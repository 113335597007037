import React, { Component, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import './BlockedWordSliders.css';

import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
//import FilterListIcon from '@material-ui/icons/FilterList';
import FilterListIcon from '@material-ui/icons/Tune';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import { withStyles } from '@material-ui/core/styles';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';


import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import CrossIcon from '@material-ui/icons/Clear';
import axios from 'axios';

import IconButton from '@material-ui/core/IconButton';
import { SvgIcon, makeStyles } from '@material-ui/core';


import * as common from '../../common.js'
import isEqual from "react-fast-compare";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';

import { fetchUserRequest, userUpdateFilter, userSaveFilter, userAddFilterRequest, userAddFilter, userSelectFilter, userDelFilter } from '../../redux/actions/index';
import { connect } from 'react-redux';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import TextField from '@material-ui/core/TextField';

import { keyframes } from "styled-components";
import { set } from 'react-ga';
function NetflixIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M140.803 258.904c-15.404 2.705-31.079 3.516-47.294 5.676l-49.458-144.856v151.073c-15.404 1.621-29.457 3.783-44.051 5.945v-276.742h41.08l56.212 157.021v-157.021h43.511v258.904zm85.131-157.558c16.757 0 42.431-.811 57.835-.811v43.24c-19.189 0-41.619 0-57.835.811v64.322c25.405-1.621 50.809-3.785 76.482-4.596v41.617l-119.724 9.461v-255.39h119.724v43.241h-76.482v58.105zm237.284-58.104h-44.862v198.908c-14.594 0-29.188 0-43.239.539v-199.447h-44.862v-43.242h132.965l-.002 43.242zm70.266 55.132h59.187v43.24h-59.187v98.104h-42.433v-239.718h120.808v43.241h-78.375v55.133zm148.641 103.507c24.594.539 49.456 2.434 73.51 3.783v42.701c-38.646-2.434-77.293-4.863-116.75-5.676v-242.689h43.24v201.881zm109.994 49.457c13.783.812 28.377 1.623 42.43 3.242v-254.58h-42.43v251.338zm231.881-251.338l-54.863 131.615 54.863 145.127c-16.217-2.162-32.432-5.135-48.648-7.838l-31.078-79.994-31.617 73.51c-15.678-2.705-30.812-3.516-46.484-5.678l55.672-126.75-50.269-129.992h46.482l28.377 72.699 30.27-72.699h47.295z" fill="#d81f26" />
    </SvgIcon>
  );
}



const filterSaveChoices = [
  {
    value: 'Default',
    label: 'Default',
  },
  {
    value: 'Custom1',
    label: 'Kids PG 80s movies with no Blasphemy',
  },
  {
    value: 'Custom2',
    label: 'Mom and Dad Drama and Action Profanity < 5 no Blasphemy',
  },
  {
    value: 'Custom3',
    label: 'Cindy romantic shows on netflix all swearing categories < 5',
  },
  {
    value: 'Custom4',
    label: 'Favourite TV Shows all swearing categories < 5',
  },
];

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    height: '14px',
    backgroundColor: '#eeeeee',
    border: '1px solid #bbbbbb',
    fontSize: '14px',
    padding: '4px 5px 10px 5px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    /*fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),*/
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);

const drawerWidth = 340;
const menuWidth = 200;

const styles = theme => ({
  "@keyframes morph": {
    "0%": {
      backgroundColor: 'rgb(254,201,0)'
    },
    "50%": {
      backgroundColor: '#ffffff'
    },
    "100%": {
      backgroundColor: 'rgb(254,201,0)'
    }
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },


  drawerPaper: {
    width: drawerWidth,
    background: '#cccccc',
    zIndex: 100
  },


  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },


  appbutton: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#cccccc',
    fontWeight: 'bold',

    marginLeft: '2px',
    marginRight: '2px',
    '&:hover': {
      //background: '#999999',
      backgroundColor: 'rgb(254,201,0)',
    },
  },

  appbuttonhighlight: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#eeeeee',
    fontWeight: 'bold',

    marginLeft: '2px',
    marginRight: '2px',
    '&:hover': {
      //background: '#999999',
      backgroundColor: 'rgb(254,201,0)',
    },
  },

  typebutton: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#cccccc',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
    '&:hover': {
      //background: '#999999',
      backgroundColor: 'rgb(254,201,0)',
    },
  },

  typebuttonhighlight: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#eeeeee',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
    '&:hover': {
      //background: '#999999',
      backgroundColor: 'rgb(254,201,0)',
    },
  },

  buttonIcons: {
    marginRight: '-5px'
  },

  checkbox: {
    fontSize: "12px"
  },

  filterbutton: {
    marginLeft: '5px',
    maxHeight: '28px',
    minHeight: '28px',

    textTransform: 'none',
    /*font-size: 18; */
    background: '#eeeeee',
    margin: '3px',
    '&:hover': {
      background: '#cccccc',
    },
  },

  showwordsbutton: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#cccccc',
    margin: '3px',
    '&:hover': {
      backgroundColor: 'rgb(254,201,0)',
    },
  },



  wordbutton: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#dddddd',
    margin: '3px',
    '&:hover': {
      background: '#999999',
    },
  },

  minmaxbutton: {
    maxHeight: '30px',
    maxWidth: '30px',
    minWidth: '30px',
    textTransform: 'none',
    /*font-size: 18; */
    background: '#cccccc',
    fontWeight: 'bold',
    marginLeft: '0px',
    marginRight: '0px',

  },

  refreshbutton: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#eeeeee',
    fontWeight: 'bold',
    marginLeft: '5px',
    marginRight: '5px',
    backgroundColor: 'rgb(254,201,0)',
    '&:hover': {
      background: '#999999',
    },
    animation: '$morph 1s linear infinite',
  },


  selectfilterbutton: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#eeeeee',
    fontWeight: 'bold',
    marginLeft: '20px',
    marginRight: '5px',
    '&:hover': {
      //background: '#999999',
      backgroundColor: 'rgb(254,201,0)',
    },
  },

  refreshbuttonDrawer: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#bbbbbb',
    fontWeight: 'bold',
    marginLeft: '20px',
    marginRight: '20px',
    backgroundColor: 'rgb(254,201,0)',
    '&:hover': {
      background: '#999999',
    },
    animation: '$morph 1s linear infinite',

  },

  netflixbutton: {
    textTransform: 'none',
    background: '#cccccc',
    /*font-size: 18; */
    width: '60px',
    height: '38px',
    fontWeight: 'bold',
    marginLeft: '10px',
    marginRight: '10px',
    '&:hover': {
      background: '#eeeeee',
    },
  },

  netflixbuttonhighlight: {
    textTransform: 'none',
    background: '#eeeeee',
    /*font-size: 18; */
    width: '60px',
    height: '38px',
    fontWeight: 'bold',
    marginLeft: '10px',
    marginRight: '10px',
    '&:hover': {
      background: '#dddddd',
    },
  },


  resetbutton: {
    textTransform: 'none',
    /*font-size: 18; */
    background: '#bbbbbb',
    /*fontSize: '10px',*/
    marginLeft: '10px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: 'rgb(254,201,0)',
    },
  }

})


export function InputText(props) {
  const [value, setValue] = useState("");
  const { onBlur = (e) => {
    //console.loge)
  },
    name = 'name',
    efaultValue = 'Default',
    type = 'text',
    label = 'Label',
    classname = "class" } = props



  return (

    <TextField
      autoFocus
      className={classname}
      name={name}
      label={label}
      onBlur={e => onBlur(e)}
      type={type}
      value={value}
      onChange={e => setValue(e.target.value)}
    />

  );
}



class WordSlider extends Component {
  state = {
    sliderValue: 0,
    sliderTitle: 'none',
    max: this.props.max,
    min: this.props.min,
    step: this.props.step,

  }

  handleSliderChange = (event, newValue) => {
    this.setState({ sliderValue: newValue })

  };


  updateParent = (event) => {
    //console.log('BlockedWordSliders: updateParent')
    this.props.OnDone(this.state.sliderValue)
  }

  componentDidMount() {
    this.setState({
      sliderValue: this.props.slidervalue,
      sliderTitle: this.props.slidertitle,
      min: this.props.min,
      max: this.props.max
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      sliderValue: nextProps.slidervalue,
      sliderTitle: nextProps.slidertitle,
      min: nextProps.min,
      max: nextProps.max
    })
  }


  render() {
    const { min, max, step } = this.state
    const { myclass } = this.props

    //console.log('min, max: ', min, max)
    return (
      <div className="slide-block-element">
        <h5>
          {this.state.sliderTitle}: {this.state.sliderValue}
        </h5>
        <div className="slide-block-grid">
          <div className="slide-block-grid-val-min">
            {min}
          </div>

          <Slider

            value={this.state.sliderValue}
            onChange={this.handleSliderChange}
            onChangeCommitted={this.updateParent}
            aria-labelledby="input-slider"
            valueLabelDisplay="auto"
            min={min}
            max={max}
            step={step}
          />
          <div className="slide-block-grid-val-max">
            {max}
          </div>


        </div>

      </div >
    )
  }
}


class YearSlider extends Component {
  state = {
    sliderStartYear: 1990,
    sliderEndYear: 2025,
    max: this.props.max,
    min: this.props.min,
    step: this.props.step,

  }

  handleStartYearChange = (event, newValue) => {

    var newValueInt = Number(newValue)
    if (newValueInt >= Number(this.state.sliderEndYear))
      this.setState({ sliderEndYear: newValueInt, sliderStartYear: newValueInt })
    else
      this.setState({ sliderStartYear: newValueInt })
  };

  handleEndYearChange = (event, newValue) => {

    var newValueInt = Number(newValue)
    if (newValueInt <= Number(this.state.sliderStartYear))
      this.setState({ sliderEndYear: newValueInt, sliderStartYear: newValueInt })
    else
      this.setState({ sliderEndYear: newValueInt })

  };


  updateParent = (event) => {

    this.props.OnDone(this.state.sliderStartYear, this.state.sliderEndYear)
  }

  componentDidMount() {
    this.setState({

      sliderStartYear: Number(this.props.sliderStartYear),
      sliderEndYear: Number(this.props.sliderEndYear),
      min: this.props.min,
      max: this.props.max
    })
  }

  componentWillReceiveProps(nextProps) {

    this.setState({

      sliderStartYear: Number(nextProps.sliderStartYear),
      sliderEndYear: Number(nextProps.sliderEndYear),
      min: nextProps.min,
      max: nextProps.max
    })
  }


  render() {
    const { min, max, step } = this.state
    const { myclass } = this.props


    return (
      <div className="slide-block-element">
        <h5>
          Start Year: {this.state.sliderStartYear}
        </h5>
        <div className="slide-block-grid">
          <div className="slide-block-grid-val-min">
            {min}
          </div>

          <Slider
            value={this.state.sliderStartYear}
            onChange={this.handleStartYearChange}
            onChangeCommitted={this.updateParent}
            aria-labelledby="input-slider"
            valueLabelDisplay="auto"
            min={min}
            max={max}
            step={step}
          />
          <div className="slide-block-grid-val-max">
            {max}
          </div>

        </div>
        <h5>
          End Year: {this.state.sliderEndYear}
        </h5>
        <div className="slide-block-grid">
          <div className="slide-block-grid-val-min">
            {min}
          </div>

          <Slider
            value={this.state.sliderEndYear}
            onChange={this.handleEndYearChange}
            onChangeCommitted={this.updateParent}
            aria-labelledby="input-slider"
            valueLabelDisplay="auto"
            min={min}
            max={max}
            step={step}
          />
          <div className="slide-block-grid-val-max">
            {max}
          </div>

        </div>

      </div >
    )
  }
}


class GenreSelect extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    genrelist: [],
    checked: {},
  }

  componentDidMount() {
    this.setState({ genrelist: this.props.genres, checked: this.props.checkedValues })
  }

  componentWillReceiveProps(nextProps) {
    //console.log('GenreSelect: componentWillReceiveProps: ', nextProps)
    /*var checkboxes = nextProps.checkValues;*/
    /*var checkboxes = {}
    if (Object.getOwnPropertyNames(this.state.checked).length == 0) {

      for (var i = 0; i < nextProps.ageratings.length; i++) {
        checkboxes[nextProps.ageratings[i]] = false
      }

      this.setState({ wordlist: nextProps.ageratings, checked: checkboxes })
    }*/
    this.setState({ genrelist: nextProps.genres, checked: nextProps.checkedValues })
  }


  handleChange = event => {

    //var newChecked = JSON.parse(JSON.stringify(this.state.checked))
    var newChecked = this.state.checked
    //console.log('genre: newChecked: ', newChecked)
    const totalChecked = Object.keys(newChecked).reduce((acc, k) => { if (newChecked[k]) return acc + 1; else return acc }, 0)
    //console.log('genre: totalChecked: ', totalChecked)


    if ((totalChecked == 4) && event.target.checked) {
      alert("Maximum of 4 categories allowed")
    }
    else {
      newChecked[[event.target.name]] = event.target.checked
      this.setState({ checked: newChecked });
      //console.log('newChecked: ', newChecked)
      this.props.OnCheck(newChecked)
    }

  };

  render() {
    const { genrelist } = this.state;
    const { classes, showwords, indexchoice } = this.props;
    {/*# DAVID*/ }
    //console.log("Genre List: ", this.state)


    return (

      <div className="word-block-element">

        {/*}
        {wordlist ?
          wordlist.map(wordlist => {
            return (
              <Button startIcon={<CrossIcon className={classes.buttonIcons} />} size={'small'} onClick={this.handleButton} className={classes.wordbutton} variant={'outlined'}>
                {wordlist[0]}
              </Button>
            )
          }) : null}


        </div>*/}


        <div>
          <div style={{ fontSize: "12px" }}>
            <i> Select genres to view in browser (maximum of 4) </i>
          </div>
          <FormGroup >
            {genrelist ?
              Object.keys(this.state.checked).map(word => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.checked[word]}
                        onChange={this.handleChange}
                        name={word}
                        color="primary"
                        size="small"
                      />
                    }

                    label={<span style={{ fontSize: '12px' }}>{word}</span>}

                  />
                )
              }) : null}

          </FormGroup>
        </div>

      </div>

    )
  }

}


class AgeSelect extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    wordlist: [],
    checked: {},
  }

  componentDidMount() {
    this.setState({ wordlist: this.props.ageratings, checked: this.props.checkedValues })
  }


  componentWillReceiveProps(nextProps) {
    //console.log'AgeSelect: componentWillReceiveProps: ', nextProps)
    /*var checkboxes = nextProps.checkValues;*/
    /*var checkboxes = {}
    if (Object.getOwnPropertyNames(this.state.checked).length == 0) {

      for (var i = 0; i < nextProps.ageratings.length; i++) {
        checkboxes[nextProps.ageratings[i]] = false
      }

      this.setState({ wordlist: nextProps.ageratings, checked: checkboxes })
    }*/

    this.setState({ wordlist: nextProps.ageratings, checked: nextProps.checkedValues })
  }

  shouldComponentUpdate() {
    //console.log'AgeSelect: shouldupdate')
    this.state.wordlist.map(word => {
      //console.log'AgeSelect word: ', word)
      //console.log'AgeSelect state: ',this.state.checked[[word]] )
    })
    return true
  }

  handleChange = event => {
    var newChecked = this.state.checked;
    newChecked[[event.target.name]] = event.target.checked
    this.setState({ checked: newChecked });
    //console.log('newChecked: ', newChecked)
    this.props.OnCheck(newChecked)

  };

  render() {
    const { wordlist } = this.state;
    const { classes, showwords, indexchoice } = this.props;
    {/*# DAVID*/ }
    //console.log("AgeSelect: ", this.state)

    return (

      <div className="word-block-element">

        {/*}
        {wordlist ?
          wordlist.map(wordlist => {
            return (
              <Button startIcon={<CrossIcon className={classes.buttonIcons} />} size={'small'} onClick={this.handleButton} className={classes.wordbutton} variant={'outlined'}>
                {wordlist[0]}
              </Button>
            )
          }) : null}



        </div>*/}


        <div>
          <div style={{ fontSize: "12px" }}>
            {/*<i> Select age ratings to include in filter (note some movies are not rated yet and will be excluded from results) </i>*/}
          </div>
          <FormGroup >
            {wordlist ?
              wordlist.map(word => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.checked[[word]]}
                        onChange={this.handleChange}
                        name={word}
                        color="primary"
                        size="small"
                      />
                    }

                    label={<span style={{ fontSize: '12px' }}>{word}</span>}

                  />
                )
              }) : null}

          </FormGroup>
        </div>

      </div>

    )
  }

}





class WordList extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    wordlist: [],
    checked: {},
  }

  componentDidMount() {
    //console.log('WordList: this.props: ', this.props)
    var checkboxes = {}

    if ((Object.getOwnPropertyNames(this.state.checked).length == 0) || (this.props.checkedwords.length > 0)) {
      //('WordList: redraw')
      this.props.blockedwords.map(v => {

        if (this.props.checkedwords.includes(v[2].toString()))
          checkboxes[v[2]] = true
        else
          checkboxes[v[2]] = false

      })
      //console.log'WordList: checkboxes: ', checkboxes)
      this.setState({ wordlist: this.props.blockedwords, checked: checkboxes })
    }
    else {
      this.props.blockedwords.map(v => {
        checkboxes[v[2]] = false
      })
      this.setState({ wordlist: this.props.blockedwords, checked: checkboxes })
    }
  }

  componentWillReceiveProps(nextProps) {
    var checkboxes = {}

    //console.log'WordList all : ', nextProps, this.state.checked, Object.getOwnPropertyNames(this.state.checked))

    if ((Object.getOwnPropertyNames(this.state.checked).length == 0) || (nextProps.checkedwords.length > 0)) {
      //('WordList: redraw')
      nextProps.blockedwords.map(v => {

        if (nextProps.checkedwords.includes(v[2].toString()))
          checkboxes[v[2]] = true
        else
          checkboxes[v[2]] = false

      })
      //console.log'WordList: checkboxes: ', checkboxes)
      this.setState({ wordlist: nextProps.blockedwords, checked: checkboxes })
    }
    else {
      nextProps.blockedwords.map(v => {
        checkboxes[v[2]] = false
      })
      this.setState({ wordlist: nextProps.blockedwords, checked: checkboxes })
    }


  }


  handleChange = event => {
    var newChecked = this.state.checked;
    newChecked[[event.target.name]] = event.target.checked
    this.setState({ checked: newChecked });
    //console.log('newChecked: ', newChecked)
    this.props.OnCheck(newChecked)

  };

  render() {
    const { wordlist } = this.state;
    const { classes, showwords, indexchoice, description } = this.props;
    {/*# DAVID*/ }
    //console.log("BlockedWordsSliders: WordList: ", this.props)

    return (

      <div className="word-block-element">

        {/*}
        {wordlist ?
          wordlist.map(wordlist => {
            return (
              <Button startIcon={<CrossIcon className={classes.buttonIcons} />} size={'small'} onClick={this.handleButton} className={classes.wordbutton} variant={'outlined'}>
                {wordlist[0]}
              </Button>
            )
          }) : null}



        </div>*/}

        {showwords ? (
          <div>
            <div >
              <i className="wordlistTitle"> {description} </i>
            </div>
            <FormGroup >
              {wordlist ?
                wordlist.map(word => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={this.props.disabled}
                          checked={this.state.checked[[word[2]]]}
                          onChange={this.handleChange}
                          name={word[2]}
                          color="primary"
                          size="small"
                        />
                      }

                      label={<span style={{ fontSize: '12px' }}>{word[indexchoice]}</span>}

                    />
                  )
                }) : null}

            </FormGroup>
          </div>
        ) : null}
      </div>

    )
  }

}



class WordSliderFlex extends Component {

  constructor(props) {
    super(props)
    this.state = {
      sliderValue: 0,
      sliderTitle: 'none',
      blockedwords: [],
      max: this.props.max,
      min: this.props.min,
      currentmax: this.props.currentmax,
      showWords: false,
      excludeFilter: false
    }
  }



  handleSliderChange = (event, newValue) => {
    this.setState({ sliderValue: newValue })

  };


  updateParent = (event) => {
    //console.log('BlockedWordSliders: updateParent')
    this.props.OnDone(this.state.sliderValue)
  }

  updateWords = (event) => {
    //console.log('BlockedWordSliders: updateWords')
    this.setState({ showWords: !this.state.showWords })
    this.props.onShow()
  }

  handleup = (event) => {
    //console.log('BlockedWordSliders: pressed up')
    if (this.state.currentmax <= this.state.max - 50)
      this.setState({ currentmax: this.state.currentmax + 50 })
  }


  handledown = (event) => {
    //console.log('BlockedWordSliders: pressed down')
    if (this.state.currentmax >= 100)
      this.setState({ currentmax: this.state.currentmax - 50 })
  }


  componentDidMount() {
    this.setState({
      sliderValue: this.props.slidervalue,
      sliderTitle: this.props.slidertitle,
      blockedwords: this.props.blockedwords,
      excludeFilter: this.props.excludeFilter,
      showWords: this.props.showWords
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      sliderValue: nextProps.slidervalue,
      sliderTitle: nextProps.slidertitle,
      blockedwords: nextProps.blockedwords,
      excludeFilter: nextProps.excludeFilter,
      showWords: nextProps.showWords
    })
  }

  handleExclude = () => {
    //console.log('handleExlcude')
    this.setState({ excludeFilter: !this.state.excludeFilter })
    this.props.OnExclude(!this.state.excludeFilter)
  }

  render() {
    const { currentmax, min, max, blockedwords, showWords } = this.state
    const { myclass } = this.props

    var buttonTitle
    if (showWords)
      buttonTitle = "Hide words"
    else
      buttonTitle = "Filter words"
    /*//console.log('min, max: ', min, max)*/
    return (
      <div className="slide-block-element">
        <div className="slide-block-title">
          <div className="slide-block-title-p1" >
            <h5>
              {this.state.sliderTitle}: <span style={(this.state.excludeFilter) ? { fontSize: '12px', color: '#aaaaaa' } : { fontSize: '12px' }}>{this.state.sliderValue}</span>
            </h5>
          </div>
          <Button
            disabled={this.state.excludeFilter}
            onClick={this.updateWords}
            className={myclass.showwordsbutton}
            size='small'
            variant='contained'
          >
            {buttonTitle}
          </Button>
        </div>
        <div className="slide-block-exclude">
          <Checkbox
            checked={this.state.excludeFilter}
            onChange={this.handleExclude}
            name="Disable filter"
            color="primary"
            size="small"
          />
          <span style={{ fontSize: '12px' }}>Exclude this filter</span>
        </div>
        <div className="slide-block-grid-flex">
          <div className="slide-block-grid-val-min">
            {min}
          </div>

          <Slider
            disabled={this.state.excludeFilter}
            value={this.state.sliderValue}
            onChange={this.handleSliderChange}
            onChangeCommitted={this.updateParent}
            aria-labelledby="input-slider"
            valueLabelDisplay="auto"
            min={min}
            max={currentmax}

          />
          <div className="slide-block-grid-val-minmax">
            <Button onClick={this.handleup} size="small" className={myclass.minmaxbutton}>
              <UpIcon style={{ fontSize: 15 }} />
            </Button>

            <div className="slide-block-grid-val">
              {currentmax}
            </div>

            <Button onClick={this.handledown} size="small" className={myclass.minmaxbutton}>
              <DownIcon style={{ fontSize: 15 }} />
            </Button>

          </div>


        </div>



      </div >
    )
  }
}


class WordSliderWithInput extends Component {
  state = {
    sliderValue: 0,
    sliderTitle: 'none'
  }

  handleSliderChange = (event, newValue) => {
    this.setState({ sliderValue: newValue })

  };

  handleInputChange = (event) => {
    if (event.target.value === " ? ")
      this.setState({ sliderValue: Number(event.target.value) })
    else
      this.setState({ sliderValue: Number(event.target.value) })

  };

  updateParent = (event) => {
    //console.log('BlockedWordSliders: updateParent')
    this.props.OnDone(this.state.sliderValue)
  }



  handleBlur = (event) => {
    if (event.target.value < this.props.min) {
      this.setState({ sliderValue: this.props.min })
    } else if (event.target.value > this.props.max) {
      this.setState({ sliderValue: this.props.max })
    }
  };


  componentDidMount() {
    this.setState({ sliderValue: this.props.slidervalue, sliderTitle: this.props.slidertitle })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ sliderValue: nextProps.slidervalue, sliderTitle: nextProps.slidertitle })
  }

  render() {
    const { min, max } = this.props
    //console.log('min, max: ', min, max)
    return (
      <div className="slide-block-element">
        <Typography variant="h6" id="input-slider" gutterBottom>
          {this.state.sliderTitle}
        </Typography>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs>
            <Slider
              style={{ width: '120px' }}
              value={typeof this.state.sliderValue === 'number' ? this.state.sliderValue : 0}
              onChange={this.handleSliderChange}
              onChangeCommitted={this.updateParent}
              valueLabelDisplay="auto"
              aria-labelledby="input-slider"
              min={min}
              max={max}
            />
          </Grid>
          <Grid item>
            <Input
              style={{ width: '60px' }}
              className="slide-block-element-input"
              value={this.state.sliderValue}
              margin="dense"
              onChange={this.handleInputChange}
              onChangeCommitted={this.updateParent}
              onBlur={this.handleBlur}
              inputProps={{
                step: 10,
                min: { min },
                max: { max },
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
        </Grid>
      </div>
    )
  }


}


class BlockedWordSliders extends Component {
  constructor(props) {
    super(props);


    //console.log ('BlockedWordSliders constructor props: ', props)

    this.mounted = false;

    var resultDict = this.processProps(props)
   
    // Check end year - override
    const currentYear = new Date().getFullYear();
    this.sliderEndYear = currentYear;

    this.state = {
      showGenre: false,
      showYear: false,
      showSwearing: false,
      showBlasphemyWords: false,
      showProfanityWords: false,
      showSexualWords: false,
      showRacialWords: false,
      showRating: false,
      showAgeRating: false,
      showMovies: true,
      showSelectFilter: false,
      applyfilter: false,

      disableBlasphemyWords: false,
      disableProfanityWords: false,
      disableSexualWords: false,
      disableRacialWords: false,
      loading: true,

      christian: false,

      showFilterDrawer: false,
      netflixActive: false,
      onlyEnglish: false,
      filterSaveChoice: 'Default',
      filterSaveChoiceIndex: 0,
      openDialog: false,
      openNewFilterDialog: false,
      openDeleteDialog: false,
      openNoDefault: false,
      filtername: '',

      filterAgeRatingActive: resultDict.filterAgeRatingActive,
      filterSwearingActive: resultDict.filterSwearingActive,
      filterYearActive: resultDict.filterYearActive,
      filterRatingActive: resultDict.filterRatingActive,

      netflixActive: resultDict.netflixActive,
      filterSaveChoiceIndex: resultDict.filterSaveChoiceIndex,
      showMovies: resultDict.showMovies,
      onlyEnglish: resultDict.onlyEnglish,
      netflix: resultDict.netflix,
      checkedGenreDict: resultDict.checkedGenreDict,

      checkedAgeRatingsDict: resultDict.checkedAgeRatingsDict,
      checkedAgeRatingsTVDict: resultDict.checkedAgeRatingsTVDict,
      checkedAgeRatingsSearchDict: resultDict.checkedAgeRatingsSearchDict,

      checkedGenre: resultDict.checkedGenre,
      checkedAgeRatings: resultDict.checkedAgeRatings,

      blockedwords: resultDict.blockedwords,

      checkedBlasphemy: resultDict.checkedBlasphemy,
      checkedProfanity: resultDict.checkedProfanity,
      checkedSexual: resultDict.checkedSexual,
      checkedRacial: resultDict.checkedRacial
    }
  }


  processProps = (nextProps) => {
    if (nextProps.currentUser.filters.length > 0) {
      var filterChoices = nextProps.currentUser.currentfilter
      
      // Select Default Filter
      this.sliderBlasphemy = filterChoices.blasphemy
      this.sliderProfanity = filterChoices.profanity
      this.sliderSexual = filterChoices.sexual
      this.sliderRacial = filterChoices.racial
      this.sliderStartYear = filterChoices.startyear
      this.sliderEndYear = filterChoices.endyear
      this.region = filterChoices.region
      this.excludeBlasphemy = filterChoices.excludeBlasphemy
      this.excludeProfanity = filterChoices.excludeProfanity
      this.excludeSexual = filterChoices.excludeSexual
      this.excludeRacial = filterChoices.excludeRacial
      this.filterShortName = filterChoices.filterShortName
      this.filterLongName = filterChoices.filterLongName
      this.sliderRating = filterChoices.rating
      // Update the check word states
      var checkwordstatus = this.updatecheckedWords(filterChoices.blockedwords, nextProps.blockedwords)

      //console.log('BlockedWordSliders: checkedwords: ', checkwordstatus)

      /*this.sliderBlasphemy = nextProps.filterChoices['blasphemy']
      this.sliderProfanity = nextProps.filterChoices['profanity']
      this.sliderSexual = nextProps.filterChoices['sexual']
      this.sliderRacial = nextProps.filterChoices['racial']
      this.sliderStartYear = nextProps.filterChoices['startyear']
      this.sliderEndYear = nextProps.filterChoices['endyear']
      this.region = nextProps.filterChoices['region']*/
      this.genreList = Object.keys(common.genreMapping)


      if (this.sliderBlasphemy == 0)
        this.disableBlasphemyWords = true
      if (this.sliderProfanity == 0)
        this.disableProfanityWords = true
      if (this.sliderSexual == 0)
        this.disableSexualWords = true
      if (this.sliderRacial == 0)
        this.disableRacialWords = true

      var newGenreDict = {}

      var newAgeRatings = {}
      var newAgeRatingsTV = {}
      var newAgeRatingsSearch = {}


      //var genresArray = filterChoices.genres.split(',');
      var genresArray = filterChoices.genres
      Object.keys(common.genreMapping).map((key) => {
        if (genresArray.includes(key))
          newGenreDict[key] = true;
        else
          newGenreDict[key] = false;
      })


      /*var checkedGenre = this.updateCheckedGeneric(newGenreDict)
      //console.log('checkedGenre: ', genresArray, newGenreDict)*/

      /*this.genreList.map(word => {
        if (nextProps.filterChoices['genres']['movie'].includes(word))
          newGenreDict[word]=true;
      })*/


      //var ageRatingsArray = filterChoices.ageratings.split(',');

      var ageratingsArray = []
      var ageratingsTVArray = []
      var ageratingsSearchArray = []

      ageratingsArray = filterChoices.ageratings;
      //console.log('Search Array: ', ageratingsSearchArray)

      common.ageRatings.us.map(word => {
        if (ageratingsArray.includes(word))
          newAgeRatings[word] = true;
        else
          newAgeRatings[word] = false;
      })

      common.ageRatingsTV.us.map(word => {
        if (ageratingsArray.includes(word))
          newAgeRatingsTV[word] = true;
        else
          newAgeRatingsTV[word] = false;
      })

      common.ageRatingsSearch.us.map(word => {
        if (ageratingsArray.includes(word))
          newAgeRatingsSearch[word] = true;
        else
          newAgeRatingsSearch[word] = false;
      })


      var ageratingActive = false
      var swearingActive = false
      var yearActive = false
      var ratingActive = false

      if (ageratingsArray.length == 0) {
        //console.log('updateCheckedAgeRating true: ')
        ageratingActive = false
      }
      else
        ageratingActive = true

      if (this.sliderBlasphemy > 0 || this.sliderProfanity > 0 || this.sliderSexual > 0 || this.sliderRacial > 0)
        swearingActive = true;

      if (this.excludeBlasphemy && this.excludeProfanity && this.excludeSexual && this.excludeRacial)
        swearingActive = false;

      var d = new Date();
      var currentYear = d.getFullYear();

      if (this.sliderStartYear > 1900 || this.sliderEndYear < currentYear)
        yearActive = true

      if (this.sliderRating > 0)
        ratingActive = true



      //console.log('BlockedWordsSliders: state: ', newAgeRatings, newAgeRatingsTV, ageratingsArray, ageratingsTVArray)
      return {
        filterAgeRatingActive: ageratingActive,
        filterSwearingActive: swearingActive,
        filterYearActive: yearActive,
        filterRatingActive: ratingActive,

        netflixActive: filterChoices.netflix,
        filterSaveChoiceIndex: nextProps.currentUser.currentfilterIndex,
        showMovies: filterChoices.showMovies,
        onlyEnglish: filterChoices.locklanguage,
        netflix: filterChoices.netflix,
        checkedGenreDict: newGenreDict,

        checkedAgeRatingsDict: newAgeRatings,
        checkedAgeRatingsTVDict: newAgeRatingsTV,
        checkedAgeRatingsSearchDict: newAgeRatingsSearch,

        checkedGenre: genresArray,
        checkedAgeRatings: ageratingsArray,

        blockedwords: nextProps.blockedwords,

        checkedBlasphemy: checkwordstatus.checkedBlasphemy,
        checkedProfanity: checkwordstatus.checkedProfanity,
        checkedSexual: checkwordstatus.checkedSexual,
        checkedRacial: checkwordstatus.checkedRacial
      }
    }

  }


  //console.log('Category Lines: ', category, tstamps, runtime);

  updateCheckedGeneric = (newchecked) => {
    var checkedState = [];
    var keys = Object.keys(newchecked);
    for (var i = 0; i < keys.length; i++) {
      if (newchecked[keys[i]])
        checkedState.push(keys[i])
    }
    return checkedState;
  }

  updateBlasphemy = (val) => {
    //this.setState({sliderBlasphemy: val})
    this.sliderBlasphemy = val
    if (val == 0)
      this.setState({ filterSwearingActive: true, disableBlasphemyWords: true, applyFilter: true })
    else
      this.setState({ filterSwearingActive: true, disableBlasphemyWords: false, applyFilter: true })

  }

  updateShowBlasphemy = () => {
    if (this.sliderBlasphemy == 0)
      this.setState({ showBlasphemyWords: !this.state.showBlasphemyWords, disableBlasphemyWords: true })
    else
      this.setState({ showBlasphemyWords: !this.state.showBlasphemyWords, disableBlasphemyWords: false })
  }

  updateCheckedBlasphemy = (newchecked) => {

    var checkedState;
    checkedState = this.updateCheckedGeneric(newchecked)
    this.setState({ checkedBlasphemy: checkedState, applyFilter: true })

  }

  updateExcludeBlasphemy = (newExclude) => {

    this.excludeBlasphemy = newExclude
    var filterSwearingActiveState = !(this.excludeBlasphemy && this.excludeProfanity && this.excludeSexual && this.excludeRacial)


    if (newExclude)
      this.setState({ filterSwearingActive: filterSwearingActiveState, disableBlasphemyWords: true, showBlasphemyWords: false, applyFilter: true })
    else
      this.setState({ filterSwearingActive: filterSwearingActiveState, disableBlasphemyWords: false, applyFilter: true })

  }


  updateProfanity = (val) => {
    //this.setState({sliderProfanity: val})
    this.sliderProfanity = val
    if (val == 0)
      this.setState({ filterSwearingActive: true, disableProfanityWords: true, applyFilter: true })
    else
      this.setState({ filterSwearingActive: true, disableProfanityWords: false, applyFilter: true })

  }

  updateShowProfanity = () => {
    if (this.sliderProfanity == 0)
      this.setState({ showProfanityWords: !this.state.showProfanityWords, disableProfanityWords: true })
    else
      this.setState({ showProfanityWords: !this.state.showProfanityWords, disableProfanityWords: false })
  }

  updateCheckedProfanity = (newchecked) => {
    var checkedState;
    checkedState = this.updateCheckedGeneric(newchecked)
    this.setState({ checkedProfanity: checkedState, applyFilter: true })
  }

  updateExcludeProfanity = (newExclude) => {
    this.excludeProfanity = newExclude
    var filterSwearingActiveState = !(this.excludeBlasphemy && this.excludeProfanity && this.excludeSexual && this.excludeRacial)

    if (newExclude)
      this.setState({ filterSwearingActive: filterSwearingActiveState, disableProfanityWords: true, showProfanityWords: false, applyFilter: true })
    else
      this.setState({ filterSwearingActive: filterSwearingActiveState, disableProfanityWords: false, applyFilter: true })

  }

  updateSexual = (val) => {
    //this.setState({sliderSexual: val})
    this.sliderSexual = val
    if (val == 0)
      this.setState({ filterSwearingActive: true, disableSexualWords: true, applyFilter: true })
    else
      this.setState({ filterSwearingActive: true, disableSexualWords: false, applyFilter: true })
  }

  updateShowSexual = () => {
    if (this.sliderSexual == 0)
      this.setState({ showSexualWords: !this.state.showSexualWords, disableSexualWords: true })
    else
      this.setState({ showSexualWords: !this.state.showSexualWords, disableSexualWords: false })
  }

  updateCheckedSexual = (newchecked) => {
    var checkedState;
    checkedState = this.updateCheckedGeneric(newchecked)
    this.setState({ checkedSexual: checkedState, applyFilter: true })
  }

  updateExcludeSexual = (newExclude) => {
    this.excludeSexual = newExclude
    var filterSwearingActiveState = !(this.excludeBlasphemy && this.excludeProfanity && this.excludeSexual && this.excludeRacial)

    if (newExclude)
      this.setState({ filterSwearingActive: filterSwearingActiveState, disableSexuallWords: true, showSexualWords: false, applyFilter: true })
    else
      this.setState({ filterSwearingActive: filterSwearingActiveState, disableSexualWords: false, applyFilter: true })

  }


  updateRacial = (val) => {
    //this.setState({sliderRacial: val})
    this.sliderRacial = val
    if (val == 0)
      this.setState({ filterSwearingActive: true, disableRacialWords: true, applyFilter: true })
    else
      this.setState({ filterSwearingActive: true, disableRacialWords: false, applyFilter: true })
  }

  updateShowRacial = () => {
    if (this.sliderRacial == 0)
      this.setState({ showRacialWords: !this.state.showRacialWords, disableRacialWords: true })
    else
      this.setState({ showRacialWords: !this.state.showRacialWords, disableRacialWords: false })

  }

  updateCheckedRacial = (newchecked) => {
    var checkedState;
    checkedState = this.updateCheckedGeneric(newchecked)
    this.setState({ checkedRacial: checkedState, applyFilter: true })
  }


  updateExcludeRacial = (newExclude) => {

    this.excludeRacial = newExclude
    var filterSwearingActiveState = !(this.excludeBlasphemy && this.excludeProfanity && this.excludeSexual && this.excludeRacial)

    if (newExclude)
      this.setState({ filterSwearingActive: filterSwearingActiveState, disableRacialWords: true, showRacialWords: false, applyFilter: true })
    else
      this.setState({ filterSwearingActive: filterSwearingActiveState, disableRacialWords: false, applyFilter: true })
  }


  updateStartYear = (val) => {
    //this.setState({sliderRacial: val})
    this.sliderStartYear = val
    this.setState({ filterYearActive: true, applyFilter: true })
  }

  updateEndYear = (val) => {
    //this.setState({sliderRacial: val})
    this.sliderEndYear = val
    this.setState({ filterYearActive: true, applyFilter: true })
  }

  updateYears = (startYear, endYear) => {
    this.sliderStartYear = startYear
    this.sliderEndYear = endYear
    this.setState({ filterYearActive: true, applyFilter: true })

  }

  updateRating = (val) => {
    //this.setState({sliderRacial: val})
    this.sliderRating = val
    this.setState({ filterRatingActive: true, applyFilter: true })
  }


  hideGenre = () => {
    var theDiv = document.getElementById('genreFilters');
    theDiv.style.display = "none"
    this.setState({ showGenre: false })
  }

  showGenre = () => {
    var theDiv = document.getElementById('genreFilters');
    theDiv.style.display = "block"
    this.setState({ showGenre: true })
  }

  hideYear = () => {
    var theDiv = document.getElementById('yearFilters');
    theDiv.style.display = "none"
    this.setState({ showYear: false })
  }

  showYear = () => {
    var theDiv = document.getElementById('yearFilters');
    theDiv.style.display = "block"
    this.setState({ showYear: true })
  }

  hideSwearing = () => {
    var theDiv = document.getElementById('swearFilters');
    theDiv.style.display = "none"
    this.setState({ showSwearing: false })
  }

  showSwearing = () => {
    var theDiv = document.getElementById('swearFilters');
    theDiv.style.display = "block"
    this.setState({ showSwearing: true })
  }

  hideAgeRating = () => {
    var theDiv = document.getElementById('ageratingFilters');
    theDiv.style.display = "none"
    this.setState({ showAgeRating: false })
  }

  showAgeRating = () => {
    var theDiv = document.getElementById('ageratingFilters');
    theDiv.style.display = "block"
    this.setState({ showAgeRating: true })
  }

  hideRating = () => {
    /*var theDiv = document.getElementById('ratingFilters');
    theDiv.style.display = "none"
    this.setState({ showRating: false })*/
  }

  showRating = () => {
    var theDiv = document.getElementById('ratingFilters');
    theDiv.style.display = "block"
    this.setState({ showRating: true })
  }

  hideSelectFilter = () => {
    var theDiv = document.getElementById('selectFilters');
    theDiv.style.display = "none"

    this.setState({ showSelectFilter: false })
  }

  showSelectFilter = () => {
    var theDiv = document.getElementById('selectFilters');
    theDiv.style.display = "block"

    this.setState({ showSelectFilter: true })
  }


  handleGenre = () => {
    if (this.state.showGenre) {
      this.hideGenre();
    }
    else {
      this.showGenre();
      this.hideAgeRating();
      this.hideYear();
      this.hideRating();
      this.hideSwearing();
      this.hideSelectFilter();
    }
  }


  handleSwearing = () => {

    if (this.state.showSwearing) {
      this.hideSwearing();
    }
    else {
      this.showSwearing();
      this.hideGenre();
      this.hideYear();
      this.hideRating();
      this.hideAgeRating();
      this.hideSelectFilter();
    }
  }


  handleYear = () => {
    if (this.state.showYear) {
      this.hideYear();
    }
    else {
      this.showYear();
      this.hideGenre();
      this.hideSwearing();
      this.hideRating();
      this.hideAgeRating();
      this.hideSelectFilter();
    }
  }



  handleAgeRating = () => {
    if (this.state.showAgeRating) {
      this.hideAgeRating();
    }
    else {
      this.showAgeRating();
      this.hideGenre();
      this.hideYear();
      this.hideRating();
      this.hideSwearing();
      this.hideSelectFilter();
    }
  }



  handleRating = () => {
    if (this.state.showRating) {
      this.hideRating();
    }
    else {
      this.showRating();
      this.hideGenre();
      this.hideSwearing();
      this.hideYear();
      this.hideAgeRating();
      this.hideSelectFilter();
    }
  }

  handleSelectFilter = () => {
    if (this.state.showSelectFilter) {
      this.hideSelectFilter();
    }
    else {
      this.showSelectFilter();
      this.hideRating();
      this.hideGenre();
      this.hideSwearing();
      this.hideYear();
      this.hideAgeRating();
    }
  }



  selectTV = () => {
    if (this.state.showMovies || this.state.christian || this.props.activesearch) {
      this.setState({ showMovies: false, christian: false })
      setTimeout(() => {
        this.props.closeSearch()
        this.handleRefresh();
      }, 100);
    }
  }
  selectMovie = () => {
    if (!this.state.showMovies || this.state.christian || this.props.activesearch) {
      this.setState({ showMovies: true, christian: false })
      setTimeout(() => {
        this.props.closeSearch()
        this.handleRefresh();
      }, 100);
    }
  }

  selectFaith = () => {
    if (!this.state.christian || this.props.activesearch) {
      this.setState({ christian: true })
      setTimeout(() => {
        this.props.closeSearch()
        this.handleRefresh();
      }, 100);
    }
  }

  handleResetYear = () => {
    var d = new Date();
    var currentYear = d.getFullYear();
    this.setState({ filterYearActive: false, applyFilter: true })
    this.sliderStartYear = 1900;
    this.sliderEndYear = currentYear;

  }

  handleResetSwearing = () => {

    this.excludeBlasphemy = true;
    this.excludeProfanity = true;
    this.excludeSexual = true;
    this.excludeRacial = true;

    /*this.sliderBlasphemy = 0;
    this.sliderProfanity = 10;
    this.sliderSexual = 5;
    this.sliderRacial = 5;*/


    this.setState({
      filterSwearingActive: false,
      disableBlasphemyWords: true,
      disableProfanityWords: true,
      disableSexualWords: true,
      disableRacialWords: true,
      showBlasphemyWords: false,
      showProfanityWords: false,
      showSexualWords: false,
      showRacialWords: false,
      applyFilter: true
    });



  }

  handleResetRating = () => {
    this.setState({ filterRatingActive: false, applyFilter: true })
    this.sliderRating = 0;
  }



  handleResetAgeRating = () => {

    if (this.props.activesearch) {
      var newAgeRatingsSearchDict = this.state.checkedAgeRatingsSearchDict
      Object.keys(newAgeRatingsSearchDict).forEach(v => newAgeRatingsSearchDict[v] = false)
      this.setState({ checkedAgeRatingsSearchDict: newAgeRatingsSearchDict, checkedAgeRatings: [], filterAgeRatingActive: false, applyFilter: true })
    }
    else {

      if (this.state.showMovies) {
        var newAgeRatingDict = this.state.checkedAgeRatingsDict
        Object.keys(newAgeRatingDict).forEach(v => newAgeRatingDict[v] = false)
        this.setState({ checkedAgeRatingsDict: newAgeRatingDict, checkedAgeRatings: [], filterAgeRatingActive: false, applyFilter: true })
      }
      else {
        var newAgeRatingTVDict = this.state.checkedAgeRatingsTVDict
        Object.keys(newAgeRatingTVDict).forEach(v => newAgeRatingTVDict[v] = false)
        this.setState({ checkedAgeRatingsTVDict: newAgeRatingTVDict, checkedAgeRatings: [], filterAgeRatingActive: false, applyFilter: true })
      }
    }

  }

  handleResetGenre = () => {
    var newGenreDict = this.state.checkedGenreDict
    Object.keys(newGenreDict).forEach(v => newGenreDict[v] = false)
    //newGenreDict['Action & Adventure'] = true;
    this.setState({ checkedGenreDict: newGenreDict, checkedGenre: [], filterGenreActive: true, applyFilter: true })
  }



  updateCheckedGenre = (newchecked) => {
    //console.log('updateCheckedAgeRating: ',newchecked)
    var checkedState;
    var ageratingActive = false
    checkedState = this.updateCheckedGeneric(newchecked)
    //console.log('updateCheckedGenre: ', checkedState)


    this.setState({ checkedGenre: checkedState, applyFilter: true })

  }

  updateCheckedAgeRating = (newchecked) => {
    //console.log('updateCheckedAgeRating: ',newchecked)
    var checkedState;
    var ageratingActive = false
    checkedState = this.updateCheckedGeneric(newchecked)
    //console.log('updateCheckedAgeRating: ', checkedState)
    if (checkedState.length == 0) {
      //console.log('updateCheckedAgeRating true: ')
      ageratingActive = false
    }
    else
      ageratingActive = true
    this.setState({ filterAgeRatingActive: ageratingActive, checkedAgeRatings: checkedState, applyFilter: true })

  }


  componentDidMount() {
    //console.log('BlockedWordsSliders component mount')
    var checkboxes = {}
    this.mounted = true;
    this.genreList = Object.keys(common.genreMapping)

    document.addEventListener('mousedown', this.handleClick, false)


    //console.log('BlockedWordsSliders componentDidMount: props ', this.props)
    //console.log('BlockedWordsSliders componentDidMount: genrelist ', this.genreList)
    //console.log('BlockedWordsSliders: componentWillReceiveProps')



  }


  componentWillUnmount() {
    //('BlockedWordsSliders component unmount')
    document.removeEventListener('mousedown', this.handleClick, false)
  }


  shouldComponentUpdate(nextProps, nextState) {
    //console.log('BlockedWordsSliders shouldComponentUpdate: ', nextProps, this.props)
    if (!isEqual(nextProps, this.props) || !isEqual(nextState, this.state)) {
      //console.log('BlockedWordsSliders shouldupdate');
      //if nextState != this.state.blocked
      return true
    }
    else
      return false
  }


  updatecheckedWords = (bwords, bwordsDict) => {

    var checkedBlasphemy = []
    var checkedProfanity = []
    var checkedSexual = []
    var checkedRacial = []
    var swearingActive = false
    //const bwordsDict = this.state.blockedwords

    //console.log ('updatecheckedwords: ', bwords, bwordsDict)
    if (bwords != null) {
      bwords.map(wordID => {
        //console.log ('updatecheckedwords: wordID', wordID)
        if (bwordsDict.blasphemy.some(e => e[2] == wordID)) {
          checkedBlasphemy.push(wordID)
        }
        if (bwordsDict.profanity.some(e => e[2] == wordID)) {
          checkedProfanity.push(wordID)
        }
        if (bwordsDict.sexual.some(e => e[2] == wordID)) {
          checkedSexual.push(wordID)
        }
        if (bwordsDict.racial.some(e => e[2] == wordID)) {
          checkedRacial.push(wordID)
        }
      })
      if (bwords.length > 0)
        swearingActive = true
      else
        swearingActive = false
    }

    return { filterSwearingActive: swearingActive, checkedBlasphemy: checkedBlasphemy, checkedProfanity: checkedProfanity, checkedSexual: checkedSexual, checkedRacial: checkedRacial }


    //console.log ('updatecheckedwords: ', checkedBlasphemy)
    //this.setState({ filterSwearingActive: swearingActive, checkedBlasphemy: checkedBlasphemy, checkedProfanity: checkedProfanity, checkedSexual: checkedSexual, checkedRacial: checkedRacial })

  }

  //static getDerivedStateFromProps (nextProps, state) {
  UNSAFE_componentWillReceiveProps(nextProps) {

    //console.log('BlockedWordsSliders: props ', nextProps)
    //console.log('BlockedWordsSliders: componentWillReceiveProps')


    if (!nextProps.currentUser.pending) {
      var resultDict = this.processProps(nextProps)
      this.setState({
        filterAgeRatingActive: resultDict.filterAgeRatingActive,
        filterSwearingActive: resultDict.filterSwearingActive,
        filterYearActive: resultDict.filterYearActive,
        filterRatingActive: resultDict.filterRatingActive,

        netflixActive: resultDict.netflixActive,
        filterSaveChoiceIndex: resultDict.filterSaveChoiceIndex,
        showMovies: resultDict.showMovies,
        onlyEnglish: resultDict.onlyEnglish,
        netflix: resultDict.netflix,
        checkedGenreDict: resultDict.checkedGenreDict,

        checkedAgeRatingsDict: resultDict.checkedAgeRatingsDict,
        checkedAgeRatingsTVDict: resultDict.checkedAgeRatingsTVDict,
        checkedAgeRatingsSearchDict: resultDict.checkedAgeRatingsSearchDict,

        checkedGenre: resultDict.checkedGenre,
        checkedAgeRatings: resultDict.checkedAgeRatings,

        blockedwords: resultDict.blockedwords,

        checkedBlasphemy: resultDict.checkedBlasphemy,
        checkedProfanity: resultDict.checkedProfanity,
        checkedSexual: resultDict.checkedSexual,
        checkedRacial: resultDict.checkedRacial
      })

    }

  }

  handleRefresh = () => {
    if (this.props.wideScreen) {
      this.hideSwearing()
      this.hideYear()
      this.hideRating()
      this.hideAgeRating()
      this.hideSelectFilter()
    }

    var bwordlist = []

    if (!this.excludeBlasphemy)
      bwordlist = bwordlist.concat(this.state.checkedBlasphemy)
    if (!this.excludeProfanity)
      bwordlist = bwordlist.concat(this.state.checkedProfanity)
    if (!this.excludeSexual)
      bwordlist = bwordlist.concat(this.state.checkedSexual)
    if (!this.excludeRacial)
      bwordlist = bwordlist.concat(this.state.checkedRacial)

    //var bwordlist = this.state.checkedProfanity.concat(this.state.checkedBlasphemy, this.state.checkedSexual, this.state.checkedRacial);

    //console.log ('handleRefresh, ageratings: ',this.state)
    var filterResult = {
      filterShortName: this.filterShortName,
      filterLongName: this.filterLongName,
      blasphemy: this.sliderBlasphemy,
      profanity: this.sliderProfanity,
      sexual: this.sliderSexual,
      racial: this.sliderRacial,
      startyear: this.sliderStartYear,
      endyear: this.sliderEndYear,
      rating: this.sliderRating,
      blockedwords: bwordlist,
      ageratings: this.state.checkedAgeRatings,
      excludeBlasphemy: this.excludeBlasphemy,
      excludeProfanity: this.excludeProfanity,
      excludeSexual: this.excludeSexual,
      excludeRacial: this.excludeRacial,
      netflix: this.state.netflixActive,
      genres: this.state.checkedGenre,
      original_language: 'en',
      locklanguage: this.state.onlyEnglish,
      showMovies: this.state.showMovies,
      christian: this.state.christian
    }

    //console.log('BlockedWordSliders: handleRefresh', filterResult)
    
    this.setState({ showFilterDrawer: false, applyFilter: false })
    //this.props.OnDone(filterResult)
    this.props.userUpdateFilter(filterResult)

  }


  handleClickOutside() {
    if (this.props.wideScreen) {
      this.hideGenre();
      this.hideYear();
      this.hideSwearing();
      this.hideRating();
      this.hideAgeRating();
      this.hideSelectFilter();
    }
  }


  handleClick = (e) => {
    //console.log'handleClick: ', e.target.id)

    if (this.nodeGenre) {
      if (this.nodeGenre.contains(e.target)) {
        return
      }
    }
    if (this.nodeYear)
      if (this.nodeYear.contains(e.target)) {
        return
      }
    if (this.nodeSwearing)
      if (this.nodeSwearing.contains(e.target)) {
        return
      }
    /*if (this.nodeRating.contains(e.target)) {
      return
    }*/
    if (this.nodeAgeRating)
      if (this.nodeAgeRating.contains(e.target)) {
        return
      }
    //if (e.target.id == )
    
    if (this.props.currentUser.isLoggedIn)
      if (this.nodeSelectFilter)
        if (this.nodeSelectFilter.contains(e.target)) {
          return
        }
    

    /*if (e.target.contains('MuiListItem')) {
      return
    }*/
    if (this.props.wideScreen) {
      if (this.nodeGenreButton)
        if (this.nodeGenreButton.contains(e.target)) {
          return
        }
      if (this.nodeSwearingButton) 
        if (this.nodeSwearingButton.contains(e.target)) {
          return
        }
      if (this.nodeYearButton) 
        if (this.nodeYearButton.contains(e.target)) {
          return
        }
      /*if (this.nodeRatingButton.contains(e.target)) {
        return
      }*/
      if (this.nodeAgeRatingButton)
        if (this.nodeAgeRatingButton.contains(e.target)) {
          return
        }
      if ((e.target.id == 'FilterChoices') || (e.target.id == 'FilterChoicesMenu')) {
        return
      }
      if (this.props.currentUser.isLoggedIn)
        if (this.nodeSelectFilterButton)
          if (this.nodeSelectFilterButton.contains(e.target)) {
            return
          }
    }


    this.handleClickOutside();


  }

  netflixSelect = () => {

    this.setState({ netflixActive: !this.state.netflixActive })
    //console.log('netflix: ', this.state.netflixActive)

    setTimeout(() => {
      this.handleRefresh();
    }, 100);

  }

  englishSelect = () => {

    this.setState({ onlyEnglish: !this.state.onlyEnglish })

    setTimeout(() => {
      //console.log('english: ', this.state.onlyEnglish)
    }, 100);


  }

  christianSelect = () => {

    this.setState({ christian: !this.state.christian })
    //console.log('netflix: ', this.state.netflixActive)

    setTimeout(() => {
      this.handleRefresh();
    }, 100);

  }


  handleDrawerClose = () => {
    //console.log('handleCloseMobileFilters')
    this.setState({ showFilterDrawer: false });
  }

  handleOpenMobileFilters = () => {
    //console.log('handleOpenMobileFilters');
    this.setState({ showFilterDrawer: true });

  }

  processFilter = (shortname, longname) => {
    var bwordlist = []

    if (!this.excludeBlasphemy)
      bwordlist = bwordlist.concat(this.state.checkedBlasphemy)
    if (!this.excludeProfanity)
      bwordlist = bwordlist.concat(this.state.checkedProfanity)
    if (!this.excludeSexual)
      bwordlist = bwordlist.concat(this.state.checkedSexual)
    if (!this.excludeRacial)
      bwordlist = bwordlist.concat(this.state.checkedRacial)

    //var bwordlist = this.state.checkedProfanity.concat(this.state.checkedBlasphemy, this.state.checkedSexual, this.state.checkedRacial);

    //console.log ('updateFilter, ageratings: ',this.state)
    var filterResult = {
      filterShortName: shortname,
      filterLongName: longname,

      blasphemy: this.sliderBlasphemy,
      profanity: this.sliderProfanity,
      sexual: this.sliderSexual,
      racial: this.sliderRacial,
      startyear: this.sliderStartYear,
      endyear: this.sliderEndYear,
      rating: this.sliderRating,
      blockedwords: bwordlist,
      ageratings: this.state.checkedAgeRatings,
      excludeBlasphemy: this.excludeBlasphemy,
      excludeProfanity: this.excludeProfanity,
      excludeSexual: this.excludeSexual,
      excludeRacial: this.excludeRacial,
      netflix: this.state.netflixActive,
      genres: this.state.checkedGenre,
      original_language: 'en',
      locklanguage: this.state.onlyEnglish,
      showMovies: this.state.showMovies,
      christian: this.state.christian,
    }

    return filterResult;
  }

  updateFilter = () => {
    var newFilter = this.processFilter(this.filterShortName, this.filterLongName);
    //console.log('updateFilter: ', newFilter)
    this.props.userUpdateFilter(newFilter)

  }

  saveFilter = () => {
    var newFilter = this.processFilter(this.filterShortName, this.filterLongName);
    this.props.userSaveFilter(newFilter)
    this.setState({ openDialog: true })
  }



  createFilterWriteDB = (shortName, longName) => {
    if (shortName.length > 0) {
      //console.log('createFilterWriteDB: ', shortName)
      var newFilter = this.processFilter(shortName, longName);
      newFilter['uid'] = this.props.currentUser.uid
      this.props.userAddFilter(newFilter)
      this.setState({ openNewFilterDialog: false })
    }
  }

  delFilter = () => {
    this.props.userDelFilter()
  }



  handleCloseSaveDialog = () => {
    this.setState({ openDialog: false })
    //console.log('handleCloseDialog')
  }

  handleOpenNewFilterDialog = () => {
    this.setState({ openNewFilterDialog: true })
  }

  handleCloseNewFilterDialog = () => {
    this.setState({ openNewFilterDialog: false })
    //console.log('handleCloseDialog')
  }

  handleOpenDeleteDialog = () => {
    if (this.filterShortName == 'Default') {
      this.setState({ openNoDefault: true })
    }
    else {
      this.setState({ openDeleteDialog: true })
    }

  }



  handleCloseDeleteDialog = () => {
    this.setState({ openDeleteDialog: false })
    //console.log('handleCloseDialog')
  }

  handleOpenNoDefault = () => {
    this.setState({ openNoDefault: true })
  }
  handleCloseNoDefault = () => {
    this.setState({ openNoDefault: false })
    //console.log('handleCloseDialog')
  }



  handleFilterChange = event => {
    //console.log('handleFilterChange: ', event.target.value)
    if (this.props.wideScreen) {
      this.hideSelectFilter()
    }
    this.props.userSelectFilter(event.target.value)
    this.handleDrawerClose()
    this.setState({ filterSaveChoiceIndex: event.target.value })
  }

  handlefilternameChange = event => {

    this.setState({ openNewFilterDialog: false, filtername: event.target.value })
  }



  handleDeleteFilter = () => {
    //console.log('handleFilterDelete')
    this.props.userDelFilter(this.state.filterSaveChoiceIndex)
    var newfilterSaveChoiceIndex = this.state.filterSaveChoiceIndex
    if (newfilterSaveChoiceIndex > 0)
      newfilterSaveChoiceIndex -= 1
    this.setState({ filterSaveChoiceIndex: newfilterSaveChoiceIndex, openDeleteDialog: false })
  }

  currentYear = () => {
    var d = new Date();
    var thisYear = d.getFullYear();
    //console.log('currentYear: ', thisYear)
    return thisYear
  }


  renderFilterDrawer = (
    classes, open, blockedwords, showBlasphemyWords,
    disableBlasphemyWords, showSexualWords, disableSexualWords, showProfanityWords,
    disableProfanityWords, showRacialWords, disableRacialWords, checkedAgeRatingsDict, checkedAgeRatingsTVDict, checkedAgeRatingsSearchDict,
    checkedGenreDict, netflixActive, onlyEnglish, checkedBlasphemy,
    checkedProfanity, checkedSexual, checkedRacial, filterSaveChoiceIndex) => {

    return (
      <SwipeableDrawer
        color="black"
        className={classes.drawer}
        variant="temporary"
        disableSwipeToOpen
        anchor="left"
        open={open}
        onClose={this.handleDrawerClose}
        overflow="auto"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className="drawerMenus">

          <div className={classes.drawerHeader}>
            {(!this.props.wideScreen) && (this.state.applyFilter) ? 
            <Button
              onClick={this.handleRefresh}
              className={classes.refreshbuttonDrawer}
              variant="contained"
            >
              Apply Filter
        </Button>
        :null}
            <IconButton onClick={this.handleDrawerClose}>
              {/*theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />*/}
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />

          <div className="netflixselect">
            <div >
              <Checkbox
                checked={netflixActive}
                onChange={this.netflixSelect}
                name="Netflix"
                color="primary"
                size="small"
              />
              <span><b>Netflix</b></span>
            </div>


            <div>
              <Checkbox
                checked={onlyEnglish}
                onChange={this.englishSelect}
                name="English"
                color="primary"
                size="small"
              />
              <span><b>English</b></span>

            </div>
            {/*<div>
            <Checkbox
                checked={this.state.christian}
                onChange={this.christianSelect}
                name="Faith-based"
                color="primary"
                size="small"
              />
              <span><b>Faith-based</b></span>
          </div>*/}
          </div>
          <Divider />

          {(this.props.currentUser.isLoggedIn  && !(this.props.activesearch || this.state.christian) )?
            <div id="selectFiltersDrawer" ref={node => this.nodeSelectFilter = node} className="selectFiltersDrawer">
              <div className="filterHeading">
                <h4>
                  Select Filter
               </h4>
              </div>

              <div className="savefilterblock">
                <div id="selectFiltersDropDown" ref={node => this.nodeSelectFilterDropDown = node} className="savefilterText"><b>Current filter:</b></div>
                <Select

                  className="savefilterSelect"
                  labelId="filterChoices-label"
                  id="FilterChoices"
                  value={filterSaveChoiceIndex}
                  onChange={this.handleFilterChange}
                  input={<BootstrapInput />}
                >
                  {this.props.currentUser.filters.map((option, index) => (
                    <MenuItem id="FilterChoicesMenu" key={index} value={index}>
                      {option.filterShortName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="buttonfooter">
                <Button

                  onClick={this.saveFilter}
                  className={classes.resetbutton}
                  size='small'
                  variant='contained'
                >
                  Save
              </Button>
                <Button
                  onClick={this.handleOpenNewFilterDialog}
                  className={classes.resetbutton}
                  size='small'
                  variant='contained'
                >
                  Save as new
              </Button>

                <Button
                  onClick={this.handleOpenDeleteDialog}
                  className={classes.resetbutton}
                  size='small'
                  variant='contained'
                >
                  Delete

              </Button>
              </div>
            </div> : null}
          <Divider />

          <div id="yearFiltersDrawer" ref={node => this.nodeYear = node} className="yearFiltersDrawer">
            <div className="filterHeading">
              <h4>
                Release Year
          </h4>
              <Button
                onClick={this.handleResetYear}
                className={classes.resetbutton}
                variant="contained"
                size='small'
              >
                Clear all
        </Button>
            </div>
            <YearSlider sliderStartYear={this.sliderStartYear} sliderEndYear={this.sliderEndYear} myclass={classes} min={1900} max={2025} step={1}
              OnDone={this.updateYears} />
            {/*<WordSlider slidervalue={this.sliderStartYear} myclass={classes} min={1900} max={2022} step={1}
            slidertitle='Start Year ' OnDone={this.updateStartYear} />
          <WordSlider slidervalue={this.sliderEndYear} myclass={classes} min={1900} max={2022} step={1}
            slidertitle='End Year ' OnDone={this.updateEndYear} />*/}
            {/*} <WordSlider slidervalue={this.sliderEndYear} min={1900} max={2022} slidertitle='End Year ' OnDone={this.updateEndYear} />*/}
          </div>


          {(!this.state.christian || this.props.activesearch ) ?
          <div>
          <Divider />
          <div id="swearFiltersDrawer" ref={node => this.nodeSwearing = node} className="swearFiltersDrawer">
            <div className="filterHeading">
              <h4>
                Swearing Filter
          </h4>
              <Button
                onClick={this.handleResetSwearing}
                className={classes.resetbutton}
                variant="contained"
                size='small'
              >
                Clear all
        </Button>
            </div>
            <WordSliderFlex showWords={showBlasphemyWords} slidervalue={this.sliderBlasphemy} myclass={classes} min={0} max={500}
              currentmax={50} excludeFilter={this.excludeBlasphemy} slidertitle='Maximum Blasphemy' OnDone={this.updateBlasphemy} onShow={this.updateShowBlasphemy} OnExclude={this.updateExcludeBlasphemy} />
            {(blockedwords['blasphemy'] && (checkedBlasphemy != null)) ?
              <WordList description="Don't see titles that contains these words used in a blasphemous way" classes={classes} disabled={disableBlasphemyWords} blockedwords={blockedwords['blasphemy']} checkedwords={checkedBlasphemy} showwords={showBlasphemyWords} indexchoice={3} OnCheck={this.updateCheckedBlasphemy} />
              : null
            }
            <WordSliderFlex showWords={showProfanityWords} slidervalue={this.sliderProfanity} myclass={classes} min={0} max={1200}
              currentmax={50} excludeFilter={this.excludeProfanity} slidertitle='Maximum Profanity' OnDone={this.updateProfanity} onShow={this.updateShowProfanity} OnExclude={this.updateExcludeProfanity} />
            {(blockedwords['profanity'] && (checkedBlasphemy != null)) ?
              <WordList description="Don't see titles that contains these words" classes={classes} disabled={disableProfanityWords} blockedwords={blockedwords['profanity']} checkedwords={checkedProfanity} showwords={showProfanityWords} indexchoice={0} OnCheck={this.updateCheckedProfanity} />
              : null
            }
            <WordSliderFlex showWords={showSexualWords} slidervalue={this.sliderSexual} myclass={classes} min={0} max={150}
              currentmax={50} excludeFilter={this.excludeSexual} slidertitle='Maximum Sexual words' OnDone={this.updateSexual} onShow={this.updateShowSexual} OnExclude={this.updateExcludeSexual} />
            {(blockedwords['sexual'] && (checkedBlasphemy != null)) ?
              <WordList description="Don't see titles that contains these words" classes={classes} disabled={disableSexualWords} blockedwords={blockedwords['sexual']} checkedwords={checkedSexual} showwords={showSexualWords} indexchoice={0} OnCheck={this.updateCheckedSexual} />
              : null
            }
            <WordSliderFlex showWords={showRacialWords} slidervalue={this.sliderRacial} myclass={classes} min={0} max={150}
              currentmax={50} excludeFilter={this.excludeRacial} slidertitle='Maximum Racial words' OnDone={this.updateRacial} onShow={this.updateShowRacial} OnExclude={this.updateExcludeRacial} />
            {(blockedwords['racial'] && (checkedBlasphemy != null)) ?
              <WordList description="Don't see titles that contains these words" classes={classes} disabled={disableRacialWords} blockedwords={blockedwords['racial']} checkedwords={checkedRacial} showwords={showRacialWords} indexchoice={0} OnCheck={this.updateCheckedRacial} />
              : null
            }
          </div>
          </div>
          :null}
          {/*<Divider />

          <div id="ratingFiltersDrawer" ref={node => this.nodeRating = node} className="ratingFiltersDrawer">
            <div className="filterHeading">
              <h4>
                Rating Filter
          </h4>
              <Button
                onClick={this.handleResetRating}
                className={classes.resetbutton}
                variant="contained"
                size='small'
              >
                Clear all
        </Button>
            </div>
            <WordSlider slidervalue={this.sliderRating} myclass={classes} min={0} max={10} step={0.1}
              slidertitle='Minimum Rating (TMDB)' OnDone={this.updateRating} />

        
          </div>*/}

          {(!this.state.christian || this.props.activesearch)?
          <div>
          <Divider />
          <div id="ageratingFiltersDrawer" ref={node => this.nodeAgeRating = node} className="ageratingFiltersDrawer">
            <div className="filterHeading">
              <h4>
                Age Rating Filter
          </h4>
              <Button
                onClick={this.handleResetAgeRating}
                className={classes.resetbutton}
                variant="contained"
                size='small'
              >
                Clear all
        </Button>
            </div>

            <AgeSelect classes={classes} ageratings={this.props.activesearch ? common.ageRatingsSearch.us : (this.state.showMovies ? common.ageRatings.us : common.ageRatingsTV.us)}
              OnCheck={this.updateCheckedAgeRating} checkedValues={this.props.activesearch ? checkedAgeRatingsSearchDict : (this.state.showMovies ? checkedAgeRatingsDict : checkedAgeRatingsTVDict)} />
            {/*} <WordSlider slidervalue={this.sliderEndYear} min={1900} max={2022} slidertitle='End Year ' OnDone={this.updateEndYear} />*/}
          </div>
          </div>
          :null}
          {!(this.props.activesearch || this.state.christian) ?
          <div>
          <Divider />
          <div id="genreFiltersDrawer" ref={node => this.nodeGenre = node} className="genreFiltersDrawer">
            <div className="filterHeading">
              <h4>
                Genre Select
          </h4>
              <Button
                onClick={this.handleResetGenre}
                className={classes.resetbutton}
                variant="contained"
                size='small'
              >
                Clear all
        </Button>
            </div>
            <GenreSelect classes={classes} genres={this.genreList}
              OnCheck={this.updateCheckedGenre} checkedValues={checkedGenreDict} />
            {/*} <WordSlider slidervalue={this.sliderEndYear} min={1900} max={2022} slidertitle='End Year ' OnDone={this.updateEndYear} />*/}
          </div>
        </div>
        :null}

      </div>


      </SwipeableDrawer>
    );
  }


  render() {

    //console.log('BlockedWordSliders: Render: State: ', this.state)
    const { classes } = this.props;
    const { showYear, showSwearing, showGenre, showAgeRating, showRating, blockedwords, showBlasphemyWords,
      showProfanityWords, showSexualWords, showRacialWords, checkedBlasphemy, checkedProfanity,
      checkedSexual, checkedRacial, disableBlasphemyWords, disableProfanityWords, disableSexualWords,
      disableRacialWords, checkedAgeRatingsDict, checkedAgeRatingsTVDict, checkedAgeRatingsSearchDict, checkedGenreDict, filterSaveChoice, filterSaveChoiceIndex } = this.state;



    //console.log ('BlockedWordSliders: render: genrelist: ', this.genreList)
    //console.log('Checked values: ', blockedwords, checkedBlasphemy, checkedProfanity, checkedSexual, checkedRacial)
    //console.log('BlockedWordSliders: excludes', this.excludeRacial)
    //console.log('BlockedWordSliders: render slider startyear ', this.sliderStartYear)
    //console.log('BlockedWordSliders: props ', this.props)
    return (

      <div>
        {/*}
        {(false) ?
          <div className={!isMobile?"savefiltermenu":"savefiltermenuM"}>
            <div className={!isMobile ? 'leftsavefiltermenu' : 'leftsavefiltermenuM'} >
              <div className="savefilterText"><b>Current filter:</b></div>
              <Select
                className="savefilterSelect"
                labelId="filterChoices-label"
                id="FilterChoices"
                value={filterSaveChoiceIndex}
                onChange={this.handleFilterChange}
                input={<BootstrapInput />}
              >
                {this.props.currentUser.filters.map((option, index) => (
                  <MenuItem key={index} value={index}>
                    {option.filterShortName}
                  </MenuItem>

                ))}

              </Select>

              <Button

                onClick={this.saveFilter}
                className={classes.filterbutton}
                size='small'
                variant='contained'
              >
                Save
          </Button>
              <Button
                onClick={this.handleOpenNewFilterDialog}
                className={classes.filterbutton}
                size='small'
                variant='contained'
              >
                {!isMobile ? (
                  <div>Save as new</div>
                ) : (
                    <div>New</div>
                  )}

              </Button>

              <Button
                onClick={this.handleOpenDeleteDialog}
                className={classes.filterbutton}
                size='small'
                variant='contained'
              >
                Delete

            </Button>


            </div>
                </div> : <div className="savefiltermenu"></div>} */}


        <div className="outerfiltermenu">




          <div className="leftfiltermenu">

            {(this.props.wideScreen) ? (
              <div>
                {!(this.props.activesearch || this.state.christian) ?
                  <Button onClick={this.handleGenre} ref={node => this.nodeGenreButton = node} className={classes.appbuttonhighlight}>
                    {(showGenre) ?
                      <UpIcon marginRight="5px" style={{ fontSize: 20 }} />
                      :
                      <DownIcon marginRight="5px" style={{ fontSize: 20 }} />
                    }
                    Genres
                  </Button> : null}

                {/*style={{textTransform: 'none', background: '#eeeeee', fontWeight: 'bold', marginLeft: '20px', marginRight: '20px'}}*/}

                <Button onClick={this.handleYear} ref={node => this.nodeYearButton = node} className={(this.state.filterYearActive) ? classes.appbuttonhighlight : classes.appbutton}>
                  {(showYear) ?
                    <UpIcon marginRight="5px" style={{ fontSize: 20 }} />
                    :
                    <DownIcon marginRight="5px" style={{ fontSize: 20 }} />
                  }
                  Year
                </Button>

                {(!this.state.christian || this.props.activesearch ) ?
                <Button onClick={this.handleSwearing} ref={node => this.nodeSwearingButton = node} className={(this.state.filterSwearingActive) ? classes.appbuttonhighlight : classes.appbutton} >
                  {(showSwearing) ?
                    <UpIcon marginRight="5px" style={{ fontSize: 20 }} />
                    :
                    <DownIcon marginRight="5px" style={{ fontSize: 20 }} />
                  }
                  Swearing
                </Button>:null}

                {(!this.state.christian || this.props.activesearch)   ?
                <Button onClick={this.handleAgeRating} ref={node => this.nodeAgeRatingButton = node} className={(this.state.filterAgeRatingActive) ? classes.appbuttonhighlight : classes.appbutton} >
                  {(showAgeRating) ?
                    <UpIcon marginRight="5px" style={{ fontSize: 20 }} />
                    :
                    <DownIcon marginRight="5px" style={{ fontSize: 20 }} />
                  }
                  Age rating
                </Button>:null}
              </div>
            ) :
              (
                <div>
                  {this.renderFilterDrawer(
                    classes, this.state.showFilterDrawer, blockedwords, showBlasphemyWords,
                    disableBlasphemyWords, showSexualWords, disableSexualWords, showProfanityWords,
                    disableProfanityWords, showRacialWords, disableRacialWords, checkedAgeRatingsDict, checkedAgeRatingsTVDict, checkedAgeRatingsSearchDict,
                    checkedGenreDict, this.state.netflixActive, this.state.onlyEnglish, checkedBlasphemy,
                    checkedProfanity, checkedSexual, checkedRacial, filterSaveChoiceIndex)}
                  <Button onClick={this.handleOpenMobileFilters} ref={node => this.nodeMobileFiltersButton = node} className={classes.appbutton} >
                    <FilterListIcon marginRight="5px" style={{ fontSize: 20 }} />
                  Filters
          </Button>
                </div>
              )}


            {(this.props.wideScreen) ? (

              <div className="netflixselect">
                <Checkbox
                  checked={this.state.netflixActive}
                  onChange={this.netflixSelect}
                  name="Netflix"
                  color="primary"
                  size="small"
                />
                <span><b>Netflix</b></span>
              </div>
            ) : null}

            {(this.props.wideScreen) ? (

              <div className="englishselect">
                <Checkbox
                  checked={this.state.onlyEnglish}
                  onChange={this.englishSelect}
                  name="English"
                  color="primary"
                  size="small"
                />
                <span><b>English</b></span>

              </div>
            ) : null}

            {/*(this.props.wideScreen) ? (

              {<div className="englishselect">
              <Checkbox
                checked={this.state.christian}
                onChange={this.christianSelect}
                name="Faith-based"
                color="primary"
                size="small"
              />
              <span><b>Faith-based</b></span>
                       
            </div>}
            ) : null*/}

            {(this.props.wideScreen && this.props.currentUser.isLoggedIn && !(this.props.activesearch || this.state.christian) ) ? (
              <Button
                ref={node => this.nodeSelectFilterButton = node}
                onClick={this.handleSelectFilter}
                className={classes.selectfilterbutton}
                variant="contained"
              >
                Save/Select
              </Button>
            ) : null}

            {(this.props.wideScreen) && (this.state.applyFilter) ? (
              <Button className={classes.refreshbutton}
                onClick={this.handleRefresh}
                variant="contained"
              >
                Apply Filter
              </Button>
            ) : null}


          </div>
          
            <div className="rightfiltermenu">
              <Button
                className={(!this.state.showMovies && !this.state.christian && !this.props.activesearch ) ? classes.typebuttonhighlight : classes.typebutton}
                aria-label="Expand"

                onClick={this.selectTV}
              >
                TV
          </Button>
              <Button
                className={(this.state.showMovies && !this.state.christian && !this.props.activesearch) ? classes.typebuttonhighlight : classes.typebutton}
                aria-label="Expand"

                onClick={this.selectMovie}
              >

                Movies
          </Button>

              <Button
                className={(this.state.christian && !this.props.activesearch) ? classes.typebuttonhighlight : classes.typebutton}
                aria-label="Expand"

                onClick={this.selectFaith}
              >

                Faith
          </Button>
            </div> 

        </div>



        {(this.props.wideScreen) ?
          <div>

            <div id="genreFilters" ref={node => this.nodeGenre = node} className="genreFilters">
              <div className="filterHeading">
                <h4>
                  Genre Select
                </h4>
                <Button
                  onClick={this.handleResetGenre}
                  className={classes.resetbutton}
                  variant="contained"
                  size='small'
                >
                  Clear all
              </Button>
              </div>
              <GenreSelect classes={classes} genres={this.genreList}
                OnCheck={this.updateCheckedGenre} checkedValues={checkedGenreDict} />
              {/*} <WordSlider slidervalue={this.sliderEndYear} min={1900} max={2022} slidertitle='End Year ' OnDone={this.updateEndYear} />*/}
            </div>


            <div id="yearFilters" ref={node => this.nodeYear = node} className="yearFilters">
              <div className="filterHeading">
                <h4>
                  Release Year
                </h4>
                <Button
                  onClick={this.handleResetYear}
                  className={classes.resetbutton}
                  variant="contained"
                  size='small'
                >
                  Clear all
               </Button>
              </div>
              <YearSlider sliderStartYear={this.sliderStartYear} sliderEndYear={this.sliderEndYear} myclass={classes} min={1900} max={2025} step={1}
                OnDone={this.updateYears} />
              {/*<WordSlider slidervalue={this.sliderStartYear} myclass={classes} min={1900} max={2022} step={1}
            slidertitle='Start Year ' OnDone={this.updateStartYear} />
          <WordSlider slidervalue={this.sliderEndYear} myclass={classes} min={1900} max={2022} step={1}
            slidertitle='End Year ' OnDone={this.updateEndYear} />*/}
              {/*} <WordSlider slidervalue={this.sliderEndYear} min={1900} max={2022} slidertitle='End Year ' OnDone={this.updateEndYear} />*/}
            </div>

            <div id="swearFilters" ref={node => this.nodeSwearing = node} className="swearFilters">
              <div className="filterHeading">
                <h4>
                  Swearing Filter
                </h4>
                <Button
                  onClick={this.handleResetSwearing}
                  className={classes.resetbutton}
                  variant="contained"
                  size='small'
                >
                  Clear all
              </Button>
              </div>
              <WordSliderFlex showWords={showBlasphemyWords} slidervalue={this.sliderBlasphemy} myclass={classes} min={0} max={500}
                currentmax={50} excludeFilter={this.excludeBlasphemy} slidertitle='Maximum Blasphemy' OnDone={this.updateBlasphemy} onShow={this.updateShowBlasphemy} OnExclude={this.updateExcludeBlasphemy} />
              {(blockedwords['blasphemy'] != null) ?
                <WordList description="Don't see titles that contains these words used in a blasphemous way" classes={classes} disabled={disableBlasphemyWords} blockedwords={blockedwords['blasphemy']} checkedwords={checkedBlasphemy} showwords={showBlasphemyWords} indexchoice={3} OnCheck={this.updateCheckedBlasphemy} />
                : null
              }
              <WordSliderFlex showWords={showProfanityWords} slidervalue={this.sliderProfanity} myclass={classes} min={0} max={1200}
                currentmax={50} excludeFilter={this.excludeProfanity} slidertitle='Maximum Profanity' OnDone={this.updateProfanity} onShow={this.updateShowProfanity} OnExclude={this.updateExcludeProfanity} />
              {(blockedwords['profanity'] != null) ?
                <WordList description="Don't see titles that contains these words" classes={classes} disabled={disableProfanityWords} blockedwords={blockedwords['profanity']} checkedwords={checkedProfanity} showwords={showProfanityWords} indexchoice={0} OnCheck={this.updateCheckedProfanity} />
                : null
              }
              <WordSliderFlex showWords={showSexualWords} slidervalue={this.sliderSexual} myclass={classes} min={0} max={150}
                currentmax={50} excludeFilter={this.excludeSexual} slidertitle='Maximum Sexual words' OnDone={this.updateSexual} onShow={this.updateShowSexual} OnExclude={this.updateExcludeSexual} />
              {(blockedwords['sexual'] != null) ?
                <WordList description="Don't see titles that contains these words" classes={classes} disabled={disableSexualWords} blockedwords={blockedwords['sexual']} checkedwords={checkedSexual} showwords={showSexualWords} indexchoice={0} OnCheck={this.updateCheckedSexual} />
                : null
              }
              <WordSliderFlex showWords={showRacialWords} slidervalue={this.sliderRacial} myclass={classes} min={0} max={150}
                currentmax={50} excludeFilter={this.excludeRacial} slidertitle='Maximum Racial words' OnDone={this.updateRacial} onShow={this.updateShowRacial} OnExclude={this.updateExcludeRacial} />
              {(blockedwords['racial'] != null) ?
                <WordList description="Don't see titles that contains these words" classes={classes} disabled={disableRacialWords} blockedwords={blockedwords['racial']} checkedwords={checkedRacial} showwords={showRacialWords} indexchoice={0} OnCheck={this.updateCheckedRacial} />
                : null
              }
            </div>



            {/*<div id="ratingFilters" ref={node => this.nodeRating = node} className="ratingFilters">
              <div className="filterHeading">
                <h4>
                  Rating Filter
               </h4>
                <Button
                  onClick={this.handleResetRating}
                  className={classes.resetbutton}
                  variant="contained"
                  size='small'
                >
                  Clear all
                </Button>
              </div>
              <WordSlider slidervalue={this.sliderRating} myclass={classes} min={0} max={10} step={0.1}
                slidertitle='Minimum Rating (TMDB)' OnDone={this.updateRating} />
            </div>*/}

            <div id="selectFilters" ref={node => this.nodeSelectFilter = node} className="selectFilters">
              <div className="filterHeading">
                <h4>
                  Select Filter
               </h4>

                <Button
                  onClick={this.hideSelectFilter}
                  className={classes.resetbutton}
                  variant="contained"
                  size='small'
                >
                  Cancel
              </Button>
              </div>

              <div className="savefilterblock">
                <div id="selectFiltersDropDown" ref={node => this.nodeSelectFilterDropDown = node} className="savefilterText"><b>Current filter:</b></div>
                <Select

                  className="savefilterSelect"
                  labelId="filterChoices-label"
                  id="FilterChoices"
                  value={filterSaveChoiceIndex}
                  onChange={this.handleFilterChange}
                  input={<BootstrapInput />}
                >
                  {this.props.currentUser.filters.map((option, index) => (
                    <MenuItem id="FilterChoicesMenu" key={index} value={index}>
                      {option.filterShortName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="buttonfooter">
                <Button

                  onClick={this.saveFilter}
                  className={classes.resetbutton}
                  size='small'
                  variant='contained'
                >
                  Save
              </Button>
                <Button
                  onClick={this.handleOpenNewFilterDialog}
                  className={classes.resetbutton}
                  size='small'
                  variant='contained'
                >
                  Save as new
              </Button>

                <Button
                  onClick={this.handleOpenDeleteDialog}
                  className={classes.resetbutton}
                  size='small'
                  variant='contained'
                >
                  Delete

              </Button>
              </div>
            </div>

            <div id="ageratingFilters" ref={node => this.nodeAgeRating = node} className="ageratingFilters">
              <div className="filterHeading">
                <h4>
                  Age Rating Filter
          </h4>
                <Button
                  onClick={this.handleResetAgeRating}
                  className={classes.resetbutton}
                  variant="contained"
                  size='small'
                >
                  Clear all
        </Button>
              </div>
              <AgeSelect classes={classes} ageratings={this.props.activesearch ? common.ageRatingsSearch.us : (this.state.showMovies ? common.ageRatings.us : common.ageRatingsTV.us)}
                OnCheck={this.updateCheckedAgeRating} checkedValues={this.props.activesearch ? checkedAgeRatingsSearchDict : (this.state.showMovies ? checkedAgeRatingsDict : checkedAgeRatingsTVDict)} />


            </div>

          </div> : null
        }



        {/*<div className="slide-block">
          <WordSlider slidervalue={this.sliderBlasphemy} slidertitle='Maximum Blasphemy' OnDone={this.updateBlasphemy} />
          <WordSlider slidervalue={this.sliderProfanity} slidertitle='Maximum Profanity' OnDone={this.updateProfanity} />
          <WordSlider slidervalue={this.sliderSexual} slidertitle='Maximum Sexual words' OnDone={this.updateSexual} />
          <WordSlider slidervalue={this.sliderRacial} slidertitle='Maximum Racial words' OnDone={this.updateRacial} />
          <Button
            style={{ margin: '10px', textTransform: 'none' }}
            onClick={this.handleRefresh}
            variant="contained"
          >
            Refresh
        </Button>
        </div>
        <div className="slide-block">
          <WordSlider slidervalue={this.sliderStartYear} min={1900} max={2022} slidertitle='Start Year ' OnDone={this.updateStartYear} />
          <WordSlider slidervalue={this.sliderEndYear} min={1900} max={2022} slidertitle='End Year ' OnDone={this.updateEndYear} />
    </div> */}

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseSaveDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/*<InfoIcon style={{fontSize: '32px', padding: '10px'}}/>*/}
                Your Filter has been saved
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseSaveDialog}
              style={{ margin: '10px', textTransform: 'none' }}
              variant="contained"
              autoFocus
            >
              OK
            </Button>

          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openNewFilterDialog}
          onClose={this.handleCloseNewFilterDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>

            {/*<TextField
              autoFocus
              id="standard-email"
              label="Filter name"
              className="textBox"
              onChange={this.handlefilternameChange}
              value={this.state.filtername}
              margin="normal"
            />*/}

            <InputText
              autoFocus
              name="Filtername"
              label="Filter name"
              class="textBox"
              defaultValue={this.state.filtername}
              onBlur={this.handlefilternameChange.bind(this)}
            />


          </DialogContent>


          <DialogActions>
            <Button
              onClick={() => this.createFilterWriteDB(this.state.filtername, this.state.filtername)}
              style={{ margin: '10px', textTransform: 'none' }}
              variant="contained"

            >
              Create
            </Button>
            <Button
              onClick={this.handleCloseNewFilterDialog}
              style={{ margin: '10px', textTransform: 'none' }}
              variant="contained"

            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openDeleteDialog}
          onClose={this.handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/*<InfoIcon style={{fontSize: '32px', padding: '10px'}}/>*/}
                Are you sure you want to delete filter
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDeleteFilter}
              style={{ margin: '10px', textTransform: 'none' }}
              variant="contained"
              autoFocus
            >
              OK
            </Button>
            <Button
              onClick={this.handleCloseDeleteDialog}
              style={{ margin: '10px', textTransform: 'none' }}
              variant="contained"
              autoFocus
            >
              Canel
            </Button>

          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openNoDefault}
          onClose={this.handleCloseNoDefault}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/*<InfoIcon style={{fontSize: '32px', padding: '10px'}}/>*/}
                You cannot delete the Default filter
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseNoDefault}
              style={{ margin: '10px', textTransform: 'none' }}
              variant="contained"
              autoFocus
            >
              OK
            </Button>

          </DialogActions>
        </Dialog>



      </div >

    )
  }

}




function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    wideScreen: state.startup.wide
  };
}

const mapDispatchToProps = dispatch => ({
  fetchUser: () => dispatch(fetchUserRequest()),
  userUpdateFilter: (data) => dispatch(userUpdateFilter(data)),
  userSaveFilter: (data) => dispatch(userSaveFilter(data)),
  userAddFilter: (data) => dispatch(userAddFilterRequest(data)),
  userSelectFilter: (data) => dispatch(userSelectFilter(data)),
  userDelFilter: (data) => dispatch(userDelFilter(data))
});

const WrapBlockedWordSliders = connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BlockedWordSliders));
export default WrapBlockedWordSliders;

